import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { trackEvent } from '../../utils/analytics';

const useTrackEventOnShow = ({ eventName, eventOptions, threshold = 0 }) => {
  const [ref, inView] = useInView({
    threshold,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      trackEvent(eventName, eventOptions);
    }
  }, [inView, eventName, eventOptions]);

  return [ref];
};

export default useTrackEventOnShow;
