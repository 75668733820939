import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const propTypes = {
  threshold: PropTypes.number,
  children: PropTypes.node,
};

const Lazy = ({ threshold = 0, children = null }) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold });
  return (
    <>
      <span ref={ref} />
      {inView && children}
    </>
  );
};

Lazy.propTypes = propTypes;
export default Lazy;
